import {combineReducers} from 'redux';
import {authTokenReducer} from '@app/screens/generic/Login/redux';
import {routeStateReducer} from '@app/router/redux';
import {filterStateReducer} from '@app/components/right-sidebar/redux';
import {appSliceReducer} from './appSlice';
import {userManagementStateReducer} from '@app/screens/access-management/pages/user-management/redux';
import {accessGroupMasterStateReducer} from '@app/screens/access-management/pages/access-group-master/redux';
import {planAndMeetStatusStateReducer} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import {dcrUnlockRequestStateReducer} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/slice';
import {positionManagementStateReducer} from '@app/screens/module-specific-utilities/pages/position-management/redux';
import {approvalWorkflowStateReducer} from '@app/screens/configuration-management/pages/approval-workflow/redux/slice';
import {activityMappingStateReducer} from '@app/screens/configuration-management/pages/activity-mapping/redux';
import {expenseDAMasterStateReducer} from '@app/screens/master-data-management/pages/expense-da-master/redux';
import {componentManagementStateReducer} from '@app/screens/component-management/components/redux';
import {configurationManagementStateReducer} from '@app/screens/configuration-management/components/redux';
import {approveExpenseStateReducer} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {expenseTAMasterStateReducer} from '@app/screens/master-data-management/pages/expense-ta-master/redux';
import {generateReportStateReducer} from '@app/screens/module-specific-utilities/pages/generateReport/redux';
import {statusManagerStateReducer} from '@app/screens/module-specific-utilities/pages/status-manager/redux';
import {billLibraryStateReducer} from '@app/screens/module-specific-utilities/pages/bill-library/redux';
import {expenseStateReducer} from '@app/screens/module-specific-utilities/pages/expense/redux';
import {gspScheduleStateReducer} from '@app/screens/configuration-management/pages/gsp-schedule/redux';
import {inventoryModuleCnfReducer} from '@app/screens/inventory-management/pages/cnf-return-items/redux';
import {intransistChallanReducer} from '@app/screens/inventory-management/pages/intransit-challan/redux';
import {inTransistAccessReducer} from '@app/screens/inventory-management/pages/in-transit-access/redux';
import {approvedReturnItemReducers} from '@app/screens/inventory-management/pages/ApprovedReturnedItems/redux';
import {inventoryManagementConfiguratorStateReducer} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux';
import {expectedUtilzationDateReducers} from '@app/screens/inventory-management/pages/Expected Utilizaton Date/redux';
import {inventoryRolesReducer} from '@app/screens/inventory-management/pages/inventoryRules/redux';
import {challanShortQtyApprovalReducer} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux';
import {inventoryModulePRReducer} from '@app/screens/inventory-management/pages/PendencyReport/redux';
import {inventoryModuleLedgerReducer} from '@app/screens/inventory-management/pages/ledgerReport/redux';
import {dataCorrectionStateReducer} from '@app/screens/master-data-management/pages/data-correction/redux';
import {expenseReportsReducer} from '@app/screens/module-specific-utilities/pages/expense-reports/redux';
import {holidayManagmentReducer} from '@app/screens/holiday-management/redux';
import {leaveManagmentReducer} from '@app/screens/leave-management/redux/slice';
import { columnManagementReducer } from "@app/screens/directory-configuration/pages/column-management/redux";
import { departmentManagementReducer } from "@app/screens/directory-configuration/pages/department-management/redux";
import { directoryReducer } from "@app/screens/directory-configuration/redux";
import { hoContactCategoryReducer } from "@app/screens/directory-configuration/pages/ho-contact-category/redux/slice";
import { hoUserReducer } from "@app/screens/directory-configuration/pages/ho-user/redux";
import { socialMediaCategorytReducer } from "@app/screens/directory-configuration/pages/social-media-category/redux/slice";
import { socialMediaReducer } from "@app/screens/directory-configuration/pages/social media/redux";
import { tabManagementReducer } from "@app/screens/directory-configuration/pages/tab-management/redux";
import {onboardNewDivisionStateReducer} from '@app/screens/configuration-management/pages/onboard-new-division/redux';
import { receivedReturnedItemReducers } from '@app/screens/inventory-management/pages/receivedReturnedItems/redux';
import {inventoryComplianceStateReducer} from '@app/screens/configuration-management/pages/inventory-compliance/redux/slice';

export const rootReducer = combineReducers({
  appState: appSliceReducer,
  authState: authTokenReducer,
  routeState: routeStateReducer,
  filterState: filterStateReducer,
  userManagement: userManagementStateReducer,
  accessGroupMaster: accessGroupMasterStateReducer,
  planAndMeetStatusMaster: planAndMeetStatusStateReducer,
  dcrUnlockRequestMaster: dcrUnlockRequestStateReducer,
  positionManagementMaster: positionManagementStateReducer,
  approvalWorkflowMaster: approvalWorkflowStateReducer,
  inventoryModule: inventoryModuleCnfReducer,
  intransistChallan: intransistChallanReducer,
  inTransistAccess: inTransistAccessReducer,
  inventoryManagementConfigurator: inventoryManagementConfiguratorStateReducer,
  approvedReturnItems: approvedReturnItemReducers,
  expectedUtilizationDate: expectedUtilzationDateReducers,
  inventoryRoles: inventoryRolesReducer,
  challanShortQtyApproval: challanShortQtyApprovalReducer,
  activityMappingMaster: activityMappingStateReducer,

  expenseDAMaster: expenseDAMasterStateReducer,
  expenseTAMaster: expenseTAMasterStateReducer,
  ComponentManagement: componentManagementStateReducer,
  ConfigurationManagement: configurationManagementStateReducer,
  approveExpense: approveExpenseStateReducer,
  generateReport: generateReportStateReducer,
  statusManager: statusManagerStateReducer,
  billLibrary: billLibraryStateReducer,
  expesne: expenseStateReducer,
  gspScheduleMaster: gspScheduleStateReducer,
  expenseReport: expenseReportsReducer,
  dataCorrectionMaster: dataCorrectionStateReducer,
  pendencyReport: inventoryModulePRReducer,
  ledgerReport: inventoryModuleLedgerReducer,
  holidayManagment: holidayManagmentReducer,
  leaveManagment: leaveManagmentReducer,

  directoryState: directoryReducer,
  tabManagementState: tabManagementReducer,
  columnManagementState: columnManagementReducer,
  hoContactCategoryState: hoContactCategoryReducer,
  socialMediaState: socialMediaReducer,
  hoUserState: hoUserReducer,
  departmentManagementState: departmentManagementReducer,
  socialMediaCategoryState: socialMediaCategorytReducer,
  
  onboardNewDivisionMaster: onboardNewDivisionStateReducer,
  receivedReturnedItems:receivedReturnedItemReducers,
  inventoryComplianceMaster: inventoryComplianceStateReducer,
});

export {} from './appSlice';
