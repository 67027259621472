export const receivedItemsstyles = {
    receivedContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    receivedFilterContainer: {
      width: '99%',
      height: 'auto',
      top: '55px',
      left: '8px',
      display: 'flex',
      justifyContent: 'space-around',
      padding: '8px',
      borderRadius: '8px',
    },
    receivedFormControl: {
      margin: '8px',
    },
    RecievedIcon: {
      marginTop: '4px',
    },
  };