import {createAction, createSlice} from '@reduxjs/toolkit';

export const ReceivedReturnedItemsState = {
  isReturnedLoading: false,
  returnedState: [],
  returnedDivision: [],
  returnedHeadQuarters: [],
  selectedReturnedState: '',
  selectedReturnedDivision: '',
  selectedReturnedHeadQaurters: '',
  autoSuggestionData: [],
  receivedReturnedData: [],
  arReqApiCalled: false,
  returnedFilteredData: [],
  ReqPayloadForReceivedReturnData: {},
  selectedReceivedReturnedItems: [],
  receivedApprovedItems: [],
  ReceivedrejectItems: [],
  toggled: false,
  pageNumber: 1,
  searchPayload:{},
};

export const fetchReceivedReturnedItemsCreator = createAction<any>(
  'RECEIVED_RETURN_ITEMS_FILTER_DATA',
);
export const fetchReceivedReturnedItemsName = fetchReceivedReturnedItemsCreator(
  {},
).type;

export const fetchReceivedAutoSuggestionCreator =
  createAction<any>('RECEIVED_AUTO_DATA');
export const fetchReceivedAutoSuggestionTypeName =
  fetchReceivedAutoSuggestionCreator({}).type;
export const fetchReceivedReturnedItemsFilterCreator = createAction<any>(
  'FILTER_RECEIVED_RETURNED_ITEMS',
);
export const fetchReceivedReturnedItemsTypeName =
  fetchReceivedReturnedItemsFilterCreator({}).type;
export const fetchReceivedApprovedItemsCreator = createAction<any>(
  'RECEIVED_APPROVED_RETURNED_ITEMS',
);
export const fetchReceivedApprovedItemsTypeName =
  fetchReceivedApprovedItemsCreator({}).type;
export const fetchReceivedRejectItemsCreator = createAction<any>(
  'RECEIVED_REJECT_ITEMS',
);
export const fetchReceivedRejectItemsTypeName = fetchReceivedRejectItemsCreator(
  {},
).type;
export const receivedReturnedItemHandler = createSlice({
  name: 'APPROVED_RETURN_ITEMS',
  initialState: ReceivedReturnedItemsState,
  reducers: {
    setInventoryAdminReturnedSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        receivedReturnedData: payload,
        error: '',
      };
    },
    getArReqApiCalled: (prevState, {payload}) => {
      return {
        ...prevState,
        arReqApiCalled: payload,
      };
    },
    setInventoryAdminReceivedPayload: (prevState, {payload}) => {
      return {
        ...prevState,
        ReqPayloadForReceivedReturnData: payload,
      };
    },
    setInventoryAdminReturnedLoading: prevState => {
      return {
        ...prevState,
        isReturnedLoading: false,
        receivedReturnedData: [],
      };
    },
    setInventoryAdminReturnedError: prevState => {
      return {
        ...prevState,
        isReturnedLoading: false,
        receivedReturnedData: [],
        error: '',
      };
    },
    setInventoryAdminisReturnedLoading: prevState => {
      return {
        ...prevState,
        isReturnedLoading: false,
      };
    },
    setStatesData: (prevState, {payload}) => {
      return {
        ...prevState,
        statesLoading: false,
        returnedState: payload,
      };
    },
    setDivisions: (prevState, {payload}) => {
      return {
        ...prevState,
        divisionLoading: false,
        returnedDivision: payload,
      };
    },
    setHeadQuarters: (prevState, {payload}) => {
      return {
        ...prevState,
        HQLoading: false,
        returnedHeadQuarters: payload,
      };
    },
    setSelectedApproveState: (prevState, {payload}) => {
      return {
        ...prevState,
        selectedReturnedState: payload,
      };
    },
    setSelectedReturnedDivison: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        selectedReturnedDivision: payload,
      };
    },
    setSelectedReturnedHeadQuarters: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        selectedReturnedHeadQaurters: payload,
      };
    },
    setReturnedAutoSuggestionDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        autoSuggestionData: payload,
      };
    },
    setReturnedFilteredData: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        returnedFilteredData: payload,
      };
    },
    setSelectedReceivedReturnedItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        selectedReceivedReturnedItems: payload,
      };
    },
    setReceivedApprovedItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        receivedApprovedItems: payload,
      };
    },
    setReceivedRejectItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isReturnedLoading: false,
        ReceivedrejectItems: payload,
      };
    },
    setToggled: (preveState, {payload}) => {
      return {
        ...preveState,
        toggled: payload,
      };
    },
    setPageNumber: (prevState, {payload}) => {
      return {
        ...prevState,
        pageNumber: payload,
      };
    },
    setSearchPayload: (prevState, {payload}) => {
      return {
        ...prevState,
        searchPayload: payload,
      }
    }
  },
});

export const receivedReturnedItemActions = receivedReturnedItemHandler.actions;

export const receivedReturnedItemReducers = receivedReturnedItemHandler.reducer;
