import Typography from '@mui/material/Typography';
import {Box, Button, FormControl, MenuItem, Select, Stack} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import {
  fetchCNFHeadquartersCreator,
  fetchCNFStatesAndDivisionsCreator,
  inventoryModuleCnfActions,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/slice';
import {adminCnfSelector} from '@app/screens/inventory-management/pages/cnf-return-items/redux/selectors';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {appSelector} from '@app/store/selectors';
import {receivedItemsstyles as styles} from './styles';
import {
  fetchReceivedReturnedItemsCreator,
  receivedReturnedItemActions,
} from '@app/screens/inventory-management/pages/receivedReturnedItems/redux/slice';
import {receivedReturnedItemDataSelector} from '@app/screens/inventory-management/pages/receivedReturnedItems/redux/selector';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const ReceivedUtility: any = {
  receivedHandleStateChange: null,
  receivedHandleHeadquarters: null,
  receivedHandleGoClick: null,
  receivedHandleDivisionChange: null,
};
export const ReceivedReturnedItemsNavBar = () => {
  const receivedReturnedItemsState = useSelector(
    adminCnfSelector.getCNFStates(),
  );
  const selectedReceivedItemsDivision = useSelector(
    adminCnfSelector.getSelectedCnfDivision(),
  );
  const receivedReturnedHeadquarters = useSelector(
    adminCnfSelector.getCNFHeadquarters(),
  );
  const [receivedReturnedHQData, setReceivedReturnedHQData] = useState([]);

  const selectedReceivedReturnedState = useSelector(
    adminCnfSelector.getSelectedCnfState(),
  );
  const writeAccess = useSelector(appSelector.getUserInfo());
  const getToggled = useSelector(receivedReturnedItemDataSelector.getToogled());
  const [receivedReturnedState, setReceivedReturnedState] = useState([]);
  const [receivedReturnedDivision, setReceivedReturnedDivision] = useState<any>(
    selectedReceivedItemsDivision,
  );
  const [receivedReturnedHQ, setReceivedReturnedHQ] = useState('');
  const [enableData, setEnableData] = useState<any>(false);
  const [receivedReturnedStateData, setReceivedReturnedStateData] = useState(
    [],
  );
  const [receivedReturnedDivisionData, setReceivedReturnedDivisionData] =
    useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCNFStatesAndDivisionsCreator());
    dispatch(receivedReturnedItemActions.setSelectedReceivedReturnedItems([]));
    dispatch(inventoryModuleCnfActions.setSelectedCnfState([]));
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
    dispatch(receivedReturnedItemActions.setInventoryAdminReceivedPayload({}));
    dispatch(receivedReturnedItemActions.setToggled(true));

    return () => {
      dispatch(inventoryModuleCnfActions.setSelectedCnfState([]));
      dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
      dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
    };
  }, []);
  useEffect(() => {
    if (receivedReturnedItemsState?.stateData) {
      const States: any = [
        {id: 0, stateName: 'ALL'},
        ...receivedReturnedItemsState.stateData,
      ];
      setReceivedReturnedStateData(States);
    }
  }, [receivedReturnedItemsState]);
  const receivedHandleStateChange = (e: any) => {
    const CNF: any = receivedReturnedItemsState?.divisionData
      ? [
          {id: 0, name: 'ALL', shortName: 'ALL'},
          ...receivedReturnedItemsState.divisionData,
        ]
      : [{id: 0, name: 'ALL', shortName: 'ALL'}];
    const StateID: any =
      e?.target?.value === 0
        ? receivedReturnedStateData?.map((item: any) => item.id)
        : [e?.target?.value];
    setReceivedReturnedDivisionData(CNF);
    setEnableData(!enableData);
    dispatch(inventoryModuleCnfActions.setSelectedCnfState(StateID));
    setReceivedReturnedState(StateID);
    dispatch(receivedReturnedItemActions.getArReqApiCalled(false));
  };
  const receivedHandleHeadquarters = (e: any) => {
    const hqID: any =
      e?.target?.value === 0
        ? receivedReturnedHQData?.map((item: any) => item.hqId)
        : [e?.target?.value];
    setReceivedReturnedHQ(hqID);
    dispatch(receivedReturnedItemActions.getArReqApiCalled(false));
  };
  const receivedHandleGoClick = () => {
    const req = {
      stateId: selectedReceivedReturnedState,
      divisionIds: selectedReceivedItemsDivision,
      hQsId: receivedReturnedHQ,
      isActive: getToggled,
      pageNumber: 1,
    };
    dispatch(fetchReceivedReturnedItemsCreator(req));
    dispatch(receivedReturnedItemActions.setInventoryAdminReceivedPayload(req));
  };
  const receivedHandleDivisionChange = (e: any) => {
    const divID =
      e?.target?.value === 0
        ? receivedReturnedDivisionData?.map((item: any) => item.id)
        : [e?.target?.value];
    setReceivedReturnedDivision(divID);
    setEnableData(true);
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison(divID));
    const reqPayload = {
      stateId: [...receivedReturnedState],
      divisionId: [...divID],
    };
    dispatch(fetchCNFHeadquartersCreator(reqPayload));
    dispatch(receivedReturnedItemActions.getArReqApiCalled(false));
  };
  useEffect(() => {
    if (!enableData) {
      setReceivedReturnedDivision('');
    }
    setReceivedReturnedHQData([]);
    setReceivedReturnedHQ('');
  }, [receivedReturnedDivision, enableData]);

  useEffect(() => {
    const CNFHq: any = [
      {hqId: 0, hqNames: 'ALL'},
      ...receivedReturnedHeadquarters,
    ];
    setReceivedReturnedHQData(CNFHq);
  }, [receivedReturnedHeadquarters, receivedReturnedHQ]);
  const enableButton =
    selectedReceivedReturnedState === '' ||
    receivedReturnedDivision === '' ||
    receivedReturnedHQ === '';

  ReceivedUtility.receivedHandleStateChange = receivedHandleStateChange;
  ReceivedUtility.receivedHandleHeadquarters = receivedHandleHeadquarters;
  ReceivedUtility.receivedHandleGoClick = receivedHandleGoClick;
  ReceivedUtility.receivedHandleDivisionChange = receivedHandleDivisionChange;
  return (
    <Box sx={styles.receivedContainer}>
      <Stack spacing={2} direction="row" marginLeft={'15px'}>
        <Typography
          data-testid={'cnf return request approval'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          Received Returned Items
        </Typography>
      </Stack>
      <Box sx={styles.receivedFilterContainer}>
        <FormControl sx={styles.receivedFormControl} fullWidth>
          <InputLabel id="select-state-label">Select State</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              id: 'selectState-dropdown',
              'data-testid': 'selectState-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-state-label"
            onChange={receivedHandleStateChange}
            disabled={!writeAccess?.isWriteAccess}
            value={
              selectedReceivedReturnedState &&
              selectedReceivedReturnedState.length > 1
                ? 0
                : selectedReceivedReturnedState[0]
            }
            MenuProps={MenuProps}
            label="Select State">
            {receivedReturnedStateData &&
              receivedReturnedStateData != undefined &&
              receivedReturnedStateData.length > 0 &&
              receivedReturnedStateData.map((stateData: any) => {
                return (
                  <MenuItem value={stateData.id} key={stateData.value}>
                    {stateData.stateName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.receivedFormControl} fullWidth>
          <InputLabel id="select-division-label">Select Division</InputLabel>
          <Select
            inputProps={{
              'data-testid': 'selectDivision-dropdown',
            }}
            sx={{backgroundColor: '#ffffff'}}
            variant="outlined"
            labelId="select-division-label"
            label="Select Division"
            disabled={!writeAccess?.isWriteAccess}
            onChange={(e: any) => receivedHandleDivisionChange(e)}
            MenuProps={MenuProps}
            value={
              receivedReturnedDivision !== '' &&
              receivedReturnedDivision.length > 1
                ? 0
                : Number(receivedReturnedDivision[0]) || ''
            }>
            {receivedReturnedItemsState &&
              receivedReturnedDivisionData != undefined &&
              receivedReturnedDivisionData.length > 0 &&
              receivedReturnedDivisionData?.map((divisionData: any) => {
                return (
                  <MenuItem value={divisionData.id} key={divisionData.value}>
                    {divisionData.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.receivedFormControl} fullWidth>
          <InputLabel id="select-hq-label">Select HeadQuarters</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              'data-testid': 'selectHeadQuarters-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-hq-label"
            label="Select HeadQuarters"
            MenuProps={MenuProps}
            disabled={!writeAccess?.isWriteAccess}
            onChange={e => receivedHandleHeadquarters(e)}
            value={
              receivedReturnedHQ !== '' && receivedReturnedHQ.length > 1
                ? 0
                : Number(receivedReturnedHQ[0]) || ''
            }>
            {receivedReturnedHQData &&
              receivedReturnedHQData.length > 0 &&
              receivedReturnedHQData.map((hqData: any) => {
                return (
                  <MenuItem value={hqData.hqId} key={hqData.hqId}>
                    {hqData.hqNames}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Button
          data-testid={'go-button'}
          sx={styles.receivedFormControl}
          variant="contained"
          size="small"
          onClick={receivedHandleGoClick}
          disabled={!writeAccess?.isWriteAccess || enableButton}>
          Go
        </Button>
      </Box>
    </Box>
  );
};
