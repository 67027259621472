import {createSelector} from '@reduxjs/toolkit';

export const receivedReturnedItemDataSelector = {
  getReceivedReturnedItems: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.receivedReturnedData],
      state => state,
    );
  },
  getArReqApiCalledStatus: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.arReqApiCalled],
      arReqApiCalled => arReqApiCalled,
    );
  },
  getAdminReceivedReturnedItemsLoading: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.isReturnedLoading],
      isCnfLoading => isCnfLoading,
    );
  },
  getApproveStates: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.approveStates],
      approveStates => approveStates,
    );
  },
  getApproveDivisions: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.approveDivisions],
      approveDivisions => approveDivisions,
    );
  },

  getApproveHeadquarters: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.approveHeadQuarters],
      approveHeadQuarters => approveHeadQuarters,
    );
  },
  getReturnedAutoSuggestionData: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.autoSuggestionData],
      state => state,
    );
  },
  getReceivedReturnedFilteredData: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.returnedFilteredData],
      state => state,
    );
  },
  getSelectedReceivedReturnItems: () => {
    return createSelector(
      [
        (state: any) =>
          state?.receivedReturnedItems?.selectedReceivedReturnedItems,
      ],
      selectedReceivedReturnedItems => selectedReceivedReturnedItems,
    );
  },
  getSelectedApproveState: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.selectedApproveState],
      approveSelectedState => approveSelectedState,
    );
  },
  getSelectedApproveDivision: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.selectedApproveDivision],
      selectedapproveDivision => selectedapproveDivision,
    );
  },
  getSelectedApproveHeadquarter: () => {
    return createSelector(
      [
        (state: any) =>
          state?.receivedReturnedItems?.selectedApproveHeadQuarter,
      ],
      selectedapproveHeadQuarter => selectedapproveHeadQuarter,
    );
  },
  getRejectItems: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.rejectItems],
      rejectItems => rejectItems,
    );
  },
  getapprovedItems: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.rejectItems],
      approvedItems => approvedItems,
    );
  },
  getReceivedReturnPayload: () => {
    return createSelector(
      [
        (state: any) =>
          state?.receivedReturnedItems?.ReqPayloadForReceivedReturnData,
      ],
      state => state,
    );
  },
  getToogled: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.toggled],
      state => state,
    );
  },
  getPageNumber: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.pageNumber],
      state => state,
    );
  },
  getSearchPayload: () => {
    return createSelector(
      [(state: any) => state?.receivedReturnedItems?.searchPayload],
      state => state,
    );
  },
};
