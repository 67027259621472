import {createSelector} from '@reduxjs/toolkit';

export const inventoryLedgerSelector = {
  getAutoSuggestListForStaff: () => {
    return createSelector(
      [(state: any) => state?.ledgerReport?.autoSuggestListForStaffName],
      state => state,
    );
  },
  getAutoSuggestListForItems: () => {
    return createSelector(
      [(state: any) => state?.ledgerReport?.autoSuggestListItemORSamples],
      state => state,
    );
  },
  getLedgerReportData: () => {
    return createSelector(
      [(state: any) => state?.ledgerReport?.ledgerReportData],
      state => state,
    );
  },

  getLedgerReportIsLoading: () => {
    return createSelector(
      [(state: any) => state?.ledgerReport?.ledgerReportIsLoading],
      state => state,
    );
  },
  getLedgerPayloadData: () => {
    return createSelector(
      [(state: any) => state?.ledgerReport?.ledgerPayloadData],
      state => state,
    );
  },
  getLedgerReportExcelData: () => {
    return createSelector(
      [(state: any) => state?.ledgerReport?.ledgerReportExcelData],
      state => state,
    );
  },
  getPageChange: () => {
    return createSelector(
      [(state: any) => state?.ledgerReport?.pageChange],
      state => state,
    );
  },
};
