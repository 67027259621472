import jsPDF from 'jspdf';
import autoTable, {RowInput} from 'jspdf-autotable';
// Somewhere in your type declarations file or at the top of your file
declare module 'jspdf-autotable' {
  interface AutoTableOptions {
    theme?: 'striped' | 'grid' | 'plain';
  }
}

const exportDataToPDF = async (
  pdfColumnName: any,
  data: any,
  fileName: any,
  callback: any,
  search: any,
) => {
  const doc = new jsPDF('l', 'mm', 'a4');
  const pdfData: RowInput[] = data.map((rowData: any) => {
    return pdfColumnName.map((item: any) => {
      if (item.name === 'transactionDate') {
        return rowData[item.name].split('T')[0];
      } else {
        return rowData[item.name];
      }
    });
  });
  const TextHeightForOneLine = doc.getTextDimensions(search).h;
  const headerTextLines = doc.splitTextToSize(search, 750);
  const headerHeight = (headerTextLines.length + 4) * TextHeightForOneLine;
  let prevSampleItem: any = null;
  let currentColor = '#0CD0FC'; // Gray color

  // Customize cell rendering using didParseCell
  const customizeCell = (data: any) => {
    // Skip the header row
    if (data.section === 'head') {
      return;
    }
    // Find the column index of the itemSampleName
    const itemSampleNameIndex = pdfColumnName.findIndex(
      (col: any) => col.name === 'itemSampleName',
    );

    // If itemSampleNameIndex is not found, exit
    if (itemSampleNameIndex === -1) {
      return;
    }

    // Get the current itemSampleName from the cell data
    const currentSampleItem = data.row.raw[itemSampleNameIndex];

    // Alternate colors based on itemSampleName
    if (currentSampleItem !== prevSampleItem) {
      currentColor = currentColor === '#0CD0FC' ? '#0CFCA1' : '#0CD0FC'; // Alternate colors
      prevSampleItem = currentSampleItem;
    }

    // Apply the fill color to the cell
    data.cell.styles.fillColor = currentColor;
  };

  let headerAdded = false;
  const addHeader = () => {
    // Header Title with staff details
    doc.setFontSize(12);
    doc.text(`Staff Name: ${data[0].staffname}`, 12, 15);
    doc.text(`Division: ${data[0].divisionName}`, 12, 22);
    doc.text(`Designation: ${data[0].designationName}`, 12, 29);
    doc.text(`HQ Name: ${data[0].hqName}`, 12, 36);
    doc.text(`SAP Code: ${data[0].sapcode}`, 12, 43);
  };
  autoTable(doc, {
    startY: headerAdded ? 50 : 20 + headerHeight,
    head: [pdfColumnName],
    body: pdfData,
    theme: 'grid' as any, // Type assertion to bypass TypeScript checks
    styles: {
      cellPadding: 1,
      valign: 'middle',
      fontSize: 7,
      halign: 'center',
      textColor: 'black',
      lineColor: 'black',
    },
    headStyles: {
      lineWidth: 0.2,
      lineColor: 'black',
      textColor: 'black',
      fillColor: '#ffe699',
      fontSize: 7.5,
      fontStyle: 'normal',
      halign: 'center',
    },
    didParseCell: customizeCell,

    willDrawPage: () => {
      // Add header only on the first page
      if (!headerAdded) {
        addHeader();
        headerAdded = true;
      }
    },
  });

  const pageCount = doc.getCurrentPageInfo();

  const date = new Date();
  const minutes = date.getMinutes();
  const dateStr = `${date.toLocaleDateString()} ${date.getHours()}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;

  for (let i = 1; i <= pageCount.pageNumber; i++) {
    doc.setPage(i);
    doc.setFontSize(8);
    doc.text(dateStr, 14, doc.internal.pageSize.height - 10);
    doc.text(
      `Page ${i} of ${pageCount.pageNumber}`,
      264,
      doc.internal.pageSize.height - 10,
    );
  }

  doc.save(`${fileName}.pdf`);
  callback();
};

export default exportDataToPDF;
