export type MR = {
  managerApprovedDaAmount: number;
  managerApprovedMiscAmount: number;
  managerApprovedTaAmount: number;
  managerApprovedTotalAmount: number;
  miscCustomTotalAmount: number;
  miscSystemTotalAmount: number;
  outStationVisits: number;
  adminApprovedDaAmount: number;
  adminApprovedMiscAmount: number;
  adminApprovedTaAmount: number;
  adminApprovedTotalAmount: number;
  adminComment: string;
  dacustomTotalAmount: number;
  dasystemTotalAmount: number;
  designation: string;
  exVisits: number;
  expenseHistoryId: number;
  expenseMonth: number | null;
  expenseYear: number;
  hq: string;
  hqVisits: number;
  staffCode: number;
  staffName: string;
  staffPositionId: number;
  staffUserId: number;
  subordinates: number;
  tacustomTotalAmount: number;
  tasystemTotalAmount: number;
  totalClaimedAmount: number;
  totalDistance: number;
  totalSystemAmount: number;
};

export type MANAGER = {
  exVisits: number;
  expenseHistoryId: number;
  expenseMonth: number | null;
  expenseYear: number;
  hq: string;
  adminApprovedDaAmount: number;
  adminApprovedMiscAmount: number;
  adminApprovedTaAmount: number;
  adminApprovedTotalAmount: number;
  adminComment: string;
  dacustomTotalAmount: number;
  dasystemTotalAmount: number;
  designation: string;
  hqVisits: number;
  managerApprovedDaAmount: number;
  managerApprovedMiscAmount: number;
  managerApprovedTaAmount: number;
  managerApprovedTotalAmount: number;
  miscCustomTotalAmount: number;
  miscSystemTotalAmount: number;
  outStationVisits: number;
  staffCode: number;
  staffName: string;
  staffPositionId: number;
  staffUserId: number;
  subordinates: MR[];
  tacustomTotalAmount: number;
  tasystemTotalAmount: number;
  totalClaimedAmount: number;
  totalDistance: number;
};

export type STATUS_OBJECT = {
  id: number;
  displayOrder: number;
  isDefault: boolean;
  displayName: string;
};

export type STATE_OBJECT = {
  label: string;
  value: number;
};

export type TabPanelProps = {
  index: number;
  value: number;
  children?: React.ReactNode;
};

export type Daily_Expense = {
  id: number;
  daExAmount: null;
  dahqAmount: null;
  daOutAmount: null;
  expenseDate: null;
  activity: string;
  fromStation: string;
  toStation: string;
  hqType: 'H' | 'E' | 'O';
  distance: number;
  ta: number;
  isHoliday: boolean;
  isWeekOff: boolean;
  staffUserId: number;
  expenseDetailId: number;
  dcrId: number;
  staffPositionId: number;
  expenseDay: number;
  expenseMonth: number | null;
  expenseYear: number;
  taClaimed: number;
  taChangeReason: string;
  managerPassedTA: number | string | null;
  managerChangeReasonTA: string | null;
  adminPassedDistance: number;
  adminPassedTA: number;
  adminChangeReasonTA: string | null;
  da: number;
  dAclaimed: number;
  dAchangeReason: string;
  managerPassedDA: number | string | null;
  managerPassedReasonDA: string | null;
  hqTypeAdmin: 'H' | 'E' | 'O';
  adminPassedDA: number;
  adminChangeReasonDA: string;
  workWith: string | null;
  noOfDoctors: number;
  noofChemists: number;
  remarks: string;
  dcrDayCode: number | string | null;
  taRate: number;
  billPath: string | null;
};

export type Misc_Expense = {
  adminChangeReason: string | null;
  amount: number;
  expenseMonth: number;
  expenseType: string | null;
  expenseTypeId: number;
  amountAdminPassed: number;
  amountClaimed: number;
  amountManagerPassed: number;
  changeReason: string | null;
  dcrExpCode: number | string | null;
  expMiscHistoyId: number;
  expenseYear: number;
  managerChangeReason: string | null;
  staffPositionId: number;
  userId: number;
};

export type SEARCH_OBJECT = {
  employee: string;
  staffPositionId: number;
  userId: number;
  fullName: string;
  employeeCode: number;
};
export type TA_DATA = {
  id: number;
  systemAmount: number;
  amount: number;
  remarkOthers: string;
  managerApprovedAmount: string;
  managerremark: string;
  totaldistance: number;
  adminApprovedAmount: number;
  adminRemarks: number;
  isRuleEngineCalculated: boolean;
  billpath: string;
};
export type DA_DATA = {
  id: number;
  systemAmount: number;
  amount: number;
  remarkOthers: string;
  managerApprovedAmount: string;
  managerremark: string;
  hqTypeAdmin: string;
  adminApprovedAmount: number;
  adminRemarks: number;
  isRuleEngineCalculated: boolean;
  dahqAmount: number;
  daExAmount: number;
  daOutAmount: number;
  billpath: string;
};
export type MONTH_OBJECT = {
  value: string;
  key: number;
};

export type Daily_Count = {
  H?: number;
  adminPassedTA?: number;
  adminPassedDA?: number;
  O?: number;
  E?: number;
  adminPassedDistance?: number;
};

export type Misc_Count = {
  amountAdminPassed?: number;
};

export type FLAT_EXCEL = {
  expenseHistoryId?: number;
  expenseMonth?: number;
  expenseYear?: number;
  divisionName: string;
  adminComment?: string;
  staffPositionId?: number;
  staffUserId?: number;
  sapCode?: number;
  staffCode?: number | null;
  staffName?: string;
  stateName?: string;
  tasystemTotalAmount?: number;
  dasystemTotalAmount?: number;
  miscSystemTotalAmount?: number;
  designationName?: string;
  hqName?: string;
  totalDistance?: number;
  totalSystemAmount?: number;
  tacustomTotalAmount?: number;
  dacustomTotalAmount?: number;
  miscCustomTotalAmount?: number;
  totalClaimedAmount?: number;
  managerApprovedTaAmount?: number;
  managerApprovedDaAmount?: number;
  managerApprovedMiscAmount?: number;
  managerApprovedTotalAmount?: number;
  adminApprovedTaAmount?: number;
  adminApprovedDaAmount?: number;
  adminApprovedMiscAmount?: number;
  adminApprovedTotalAmount?: number;
  hqVisits?: number;
  exVisits?: number;
  outStationVisits?: number;
  subordinates?: FLAT_EXCEL[];
};


export enum ExpenseCategory {
  TA = 'TA',
  DA = 'DA',
  MISC = 'MISC',
}
export enum ReportType {
  Status_Report = 'statusreport',
  Generate_Report = 'Generate Report',
}
export enum Button_Modes {
  OUTLINED = 'outlined',
  CONTAINED = 'contained',
  TEXT = 'text',
}
export enum Button_Sizes {
  SMALL = 'small',
  LARGE = 'large',
}
