import {call, put, takeLatest} from 'redux-saga/effects';
import {
  fetchAutoSuggestListForItemsTypename,
  fetchAutoSuggestListForStaffNameTypename,
  fetchLedgerReportDataTypename,
  inventoryModuleLedgerActions,
} from './slice';
import {API_PATH, HTTP_OK} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

export function* fetchAutoSuggestListForStaffNameWatcher() {
  yield takeLatest(
    fetchAutoSuggestListForStaffNameTypename,
    fetchAutoSuggestListForStaffNameWorker,
  );
}

export function* fetchAutoSuggestListForItemsWatcher() {
  yield takeLatest(
    fetchAutoSuggestListForItemsTypename,
    fetchAutoSuggestListForItemsWorker,
  );
}

export function* fetchLedgerReportDataWatcher() {
  yield takeLatest(fetchLedgerReportDataTypename, fetchLedgerReportDataWorker);
}

export function* fetchAutoSuggestListForStaffNameWorker(action: any): any {
  const {searchText, isActive} = action.payload;
  try {
    const url =
      API_PATH.inventoryModule.legderReportsAutoSuggestForStaff +
      `?SearchValue=${searchText}&IsActive=${isActive}`;
    const result = yield call(NetworkService.post, url, null, null);
    if (result.status === HTTP_OK) {
      yield put(
        inventoryModuleLedgerActions.setAutoSuggestListofStaff(result?.data),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchAutoSuggestListForItemsWorker(action: any): any {
  const {searchType, startDate, endDate, userId} = action.payload;
  try {
    const url =
      API_PATH.inventoryModule.getItemOrSampleByUserId +
      `?_userId=${userId}&searchType=${searchType}&_startDate=${startDate}&_endDate=${endDate}`;
    const result = yield call(NetworkService.get, url, null, null);
    if (result.status === HTTP_OK) {
      yield put(
        inventoryModuleLedgerActions.setAutoSuggestListofItemOrSample(
          result?.data,
        ),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchLedgerReportDataWorker(action: any): any {
  if (action.payload?.apiFor !== 'DownLoad') {
    yield put(inventoryModuleLedgerActions.setIsLoading(true));
  }

  try {
    const url = API_PATH.inventoryModule.getLedgerReportData;
    const result = yield call(NetworkService.post, url, action.payload, null);
    if (result.status === HTTP_OK) {
      if (action?.payload?.apiFor === 'Download') {
        yield put(
          inventoryModuleLedgerActions.setLedgerReportExcelData(result?.data),
        );
      } else {
        yield put(
          inventoryModuleLedgerActions.setLedgerReportData({
            reportData: result?.data,
          }),
        );
      }
    }
    yield put(inventoryModuleLedgerActions.setIsLoading(false));
  } catch (error) {
    console.log(error);
    ToasterService.showToaster(
      'Something went wrong while fetching data',
      ToastType.ERROR,
    );
    yield put(inventoryModuleLedgerActions.setIsLoading(false));
  }
}
